import React, { FC, useEffect } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import classNames from "classnames";
import { Trans, t } from "@lingui/macro";
import { Logo } from "../Logo";

const Brand: FC = ({ children }) => (
  <div className="p-2 md:p-4 sm:flex items-center">
    <Link href="/">
      <a
        title={t({
          id: "common.link.title",
          message: "GoWithGuide - Private Tours & Local Tour Guides",
        })}
      >
        <Logo full={true} />
      </a>
    </Link>
    {children && <div className="ml-4">{children}</div>}
  </div>
);

const Menu: FC<{ onClick: () => void; hasNotification?: boolean }> = ({
  onClick,
  hasNotification = false,
}) => (
  <div className="relative p-4 pr-2 flex items-center md:hidden">
    {hasNotification && (
      <div className="absolute w-3 h-3 right-1 top-1 bg-primary rounded-full animate-pulse"></div>
    )}
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      className="w-6 h-6 cursor-pointer"
      onClick={onClick}
    >
      <title id="GWGLogoTitle">
        <Trans id="common.svg.title">GoWithGuide</Trans>
      </title>
      <desc id="GWGLogoDesc">
        <Trans id="common.svg.description">
          Find your perfect tour guide at GoWithGuide. Relax and cherish
          unforgettable tours with loved ones. Create personalized tours for a
          truly memorable trip!
        </Trans>
      </desc>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M4 6h16M4 12h16M4 18h16"
      />
    </svg>
  </div>
);

const Search: FC<{ onClick?: () => void }> = ({ onClick }) => (
  <div className="p-2 md:p-4 flex items-center md:hidden">
    <svg
      className="w-6 h-6 cursor-pointer"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      onClick={onClick}
    >
      <title id="GWGLogoTitle">
        <Trans id="common.svg.title">GoWithGuide</Trans>
      </title>
      <desc id="GWGLogoDesc">
        <Trans id="common.svg.description">
          Find your perfect tour guide at GoWithGuide. Relax and cherish
          unforgettable tours with loved ones. Create personalized tours for a
          truly memorable trip!
        </Trans>
      </desc>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2.5}
        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
      />
    </svg>
  </div>
);

const Right: FC = ({ children }) => (
  <div className={`p-4 hidden md:block`}>{children}</div>
);

const MenuLayer: FC<{ onClick: () => void; isOpen: boolean }> = ({
  onClick,
  isOpen,
  children,
}) => {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => isOpen && onClick();

    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, []);

  return (
    <div className="absolute">
      <div className="fixed top-0 right-0 w-full h-full">
        <div
          className="absolute z-10 bg-black bg-opacity-30 w-full h-full"
          onClick={() => onClick()}
        ></div>
        <div className="absolute overflow-y-auto z-20 w-4/5 max-w-xs sm:max-w-sm bg-white h-full p-4 shadow-lg right-0">
          {children}
        </div>
      </div>
    </div>
  );
};

const Nav: FC<{ className?: string }> = ({ children, className = "" }) => {
  return (
    <ul className={`flex items-center h-full ${className}`}>{children}</ul>
  );
};

const NavItem: FC<{ className?: string }> = ({ children, className = "" }) => {
  return (
    <li
      className={`${
        className.includes("px-0") ? "px-0" : "px-3"
      } w-full mb-0 hover:underline whitespace-nowrap text-base last:pr-0 first:pl-0 ${className}`}
    >
      {children}
    </li>
  );
};

interface MenuItem {
  href?: string;
}

const MenuItem: FC<MenuItem> = ({ href, children }) => {
  if (href) {
    return (
      <Link href={href}>
        <a
          title={t({
            id: "common.link.title",
            message: "GoWithGuide - Private Tours & Local Tour Guides",
          })}
          className="text-lg font-medium my-4 block hover:underline"
        >
          {children}
        </a>
      </Link>
    );
  }

  return (
    <div className="text-lg font-medium my-4 block hover:underline cursor-pointer">
      {children}
    </div>
  );
};

const Button: FC<{ className?: string; href: string }> = ({
  className,
  children,
  href,
}) => {
  return (
    <Link href={href}>
      <a
        title={t({
          id: "common.link.title",
          message: "GoWithGuide - Private Tours & Local Tour Guides",
        })}
        className={`bg-black hover:bg-gray-800 text-white px-4 py-2 rounded-full whitespace-nowrap text-center ${className}`}
      >
        {children}
      </a>
    </Link>
  );
};

const Divider = () => {
  return <hr className="border-t my-2 border-grey-light" />;
};

interface NavbarComponents {
  Brand: typeof Brand;
  Menu: typeof Menu;
  Search: typeof Search;
  Right: typeof Right;
  MenuLayer: typeof MenuLayer;
  Nav: typeof Nav;
  NavItem: typeof NavItem;
  MenuItem: typeof MenuItem;
  Button: typeof Button;
  Divider: typeof Divider;
}

interface NavbarProps {
  fixed?: boolean;
  border?: boolean;
}

export const Navbar: FC<NavbarProps> & NavbarComponents = ({
  children,
  fixed = false,
  border = true,
}) => {
  const classes = classNames("z-20  bg-white", {
    "sticky top-0": fixed,
    "border-b": border,
  });
  return (
    <div className={classes}>
      <div className="flex justify-between w-full max-w-6xl mx-auto">
        {children}
      </div>
    </div>
  );
};

Navbar.Brand = Brand;
Navbar.Menu = Menu;
Navbar.Search = Search;
Navbar.Right = Right;
Navbar.MenuLayer = MenuLayer;
Navbar.Nav = Nav;
Navbar.NavItem = NavItem;
Navbar.MenuItem = MenuItem;
Navbar.Button = Button;
Navbar.Divider = Divider;
